<template>
  <div class="page-out">
    <div :class="isPcMobileClass()">
      <div class="page-box">
        <div class="img-box">
          <img
            :src="status === 2 ? successImg : failImg"
            alt=""
          >
        </div>
        <div class="page-title">
          {{ status === 2 ? successText : failText }}
        </div>
        <div class="page-content">
          <view-match :list="basicList" />
        </div>
      </div>
      <div class="page-box-top">
        <div class="page-tent">
          <div class="applicatTitle">客户名称</div>
          <div
            v-for="(item, index) of showHide ? custList : custList.slice(0, 5)"
            :key="index"
            class="applicatContent"
          >
            <i class="icon-joyo applicatIcon">&#xe6e8;</i>
            <i class="applicatText">{{ item }}</i>
          </div>
        </div>
        <div
          v-if="!showHide && custList.length > 5"
          class="accordion-imgbox"
          @click="operation"
        >
          <span>更多</span>
          <img
            :src="showHide ? imgsd : imgs"
            alt=""
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import viewMatch from '_c/view-match';

import { getProcessResultDetail } from '@/api/cust';
import { isPcMobile, toArrays } from '@/lib/until.js';
export default {
  components: {
    viewMatch
  },
  data() {
    return {
      status: '',
      successImg: require('../../assets/img/icon_tips_success@2x.png'),
      failImg: require('../../assets/img/icon_tips_wrong@2x.png'),
      successText: '客户集团号审核通过',
      failText: '客户集团号未通过审核',
      basicData: {
        groupCode: {
          title: '集团号',
          value: ''
        }
      },
      custList: [],
      showHide: false,
      imgs: require('../../assets/img/down@2x.png'),
      imgsd: require('../../assets/img/up@2x.png')
    };
  },
  computed: {
    basicList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.basicData);
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      getProcessResultDetail(this.$route.query.id).then(res => {
        if (res.flag) {
          const data = res.data;
          this.status = data.status;
          if (this.status === 3) {
            this.basicData = {};
          } else {
            this.basicData.groupCode.value = data.groupCode;
          }
          this.custList = data.custCnInfo !== null && data.custCnInfo !== '' ? data.custCnInfo.split(',') : [];
        } else {
          this.$showToast(res.errorMsg);
        }
      })
    },
    isPcMobileClass() {
      return isPcMobile() === 2 ? 'screenControl' : '';
    },
    operation() {
      this.showHide = true;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/components/btn-bottom.scss';
.page-out{
  background-color: $color-F7;
  .page-box{
    margin: 15px 15px 0 15px;
    overflow: hidden;
    background-color: $color-FFF;
    border-radius: 8px;
    .img-box{
      width: 60px;
      height: 60px;
      margin: 32px auto;
      margin-bottom: 24px;
      img {
        width: 60px;
      }
    }
    .page-title{
      margin: 0 52px;
      color: #23252E;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
    .page-content{
      margin: 32px 16px 0 16px;
      // border-bottom:1px solid #EAEBED;
    }
  }
  .page-box-top{
    margin: 12px 15px 0 15px;
    overflow: hidden;
    background-color: $color-FFF;
    border-radius: 8px;
    .page-tent{
      margin: 16px;
      text-align: left;
      .applicatTitle{
        margin-bottom: 16px;
        color:#23252E;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
      .applicatContent{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 12px;
        .applicatIcon{
          width: 10.5px;
          margin-right: 8px;
        }
        .applicatText{
          flex: 1;
          color:#23252E;
          font-size: 14px;
          line-height: 18px;
          @extend .one-ellipsis;
        }
      }
    }
  }
  .accordion-imgbox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    color: #999BA3;
    font-size: 12px;
    line-height: 16px;
    img{
      width: 17px;
    }
  }
}
</style>
